<template>
	<main>
		<section class="section hero-section">
			<div class="container">
				<div class="hero">
					<h1 class="hero__title">Cloud Application Development</h1>
				</div>
			</div>
		</section>
		<section class="section contact-section">
			<div class="container">
				<div class="contact-container">
					<div class="section-head">
						<Title>Welcome to Our Cloud Application Development Services</Title>
						<p>
							Unlock the power of the cloud with our expert cloud application development services. At AV DEVS, we specialize in building
							robust and scalable cloud solutions tailored to meet your unique business needs. Whether you're a startup, a growing
							enterprise, or an established organization, we can help you leverage the full potential of cloud computing to drive innovation
							and accelerate your digital transformation.
						</p>
					</div>
				</div>
			</div>
		</section>
		<section class="section services-section">
			<div class="container">
				<div class="two-col-grid">
					<div class="col image-col">
						<div class="image">
							<img src="../../assets/images/image-31.svg" alt="" />
						</div>
					</div>
					<div class="col content-col">
						<div class="content">
							<Subtitle>Elevate Your Applications</Subtitle>
							<h2 class="title">Why Choose Cloud Application Development?</h2>
							<p>
								In today's fast-paced digital landscape, traditional software development approaches are no longer sufficient to keep up
								with the rapidly evolving market demands.
							</p>
							<br />
							<p>
								Cloud application development offers unparalleled flexibility, scalability, and cost-efficiency, allowing businesses to
								streamline operations, enhance collaboration, and deliver seamless user experiences.
							</p>
							<br />
							<p>
								By harnessing the power of the cloud, you can reduce infrastructure costs, increase agility, and focus on core business
								functions while leaving the technical complexities to us.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="section services-section-inverse">
			<div class="container">
				<div class="two-col-grid">
					<div class="col content-col">
						<div class="content">
							<h2 class="title">Our Cloud Application Development Services</h2>
							<div class="grid-item" v-for="(item, i) in $store.state.siteData.cloud_application_development_services" :key="i">
								<button @click="showCollapse(i)">
									{{ item.title }}
									<img v-if="isCollapsed !== i" src="../../assets/images/arrow-down.svg" alt="arrow" />
									<img v-if="isCollapsed === i" src="../../assets/images/arrow-up.svg" alt="arrow" />
								</button>
								<transition name="slide">
									<div v-if="isCollapsed === i">
										<p>{{ item.desc }}</p>
									</div>
								</transition>
							</div>
						</div>
					</div>
					<div class="col image-col">
						<div class="image">
							<img src="../../assets/images/image-48.svg" alt="" />
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="section services-section">
			<div class="container">
				<div class="two-col-grid">
					<div class="col image-col">
						<div class="image">
							<img src="../../assets/images/image-49.svg" alt="" />
						</div>
					</div>
					<div class="col content-col">
						<div class="content">
							<Subtitle>Driving Efficiency and Flexibility</Subtitle>
							<h2 class="title">Microservices and DevOps for Agile Cloud Development</h2>
							<p>
								In the era of cloud computing, agility and rapid deployment are paramount. That's why we embrace microservices architecture
								and DevOps practices as essential components of our cloud application development process. With microservices, we break down
								complex applications into smaller, independent services that can be developed, deployed, and scaled individually.
							</p>
							<br />
							<p>
								This modular approach not only enables faster development cycles but also enhances flexibility, fault isolation, and
								scalability. Additionally, our DevOps approach ensures seamless collaboration between development and operations teams,
								enabling continuous integration, delivery, and monitoring throughout the application lifecycle. By combining microservices
								and DevOps, we empower your organization to achieve faster time-to-market, improved scalability, and higher overall
								application quality in the cloud environment.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="section contact-section">
			<div class="container">
				<div class="contact-container">
					<div class="section-head">
						<Subtitle>
							Platforms We Champion
						</Subtitle>
						<Title>Cloud Platforms We Work With</Title>
					</div>
					<div class="contact-cards">
						<div class="contact-card">
							<div class="contact-card__icon">
								<img src="@/assets/images/aws.svg" alt="Amazon Web Services (AWS)" />
							</div>
							<p class="contact-card__title">Amazon Web Services (AWS)</p>
						</div>
						<div class="contact-card">
							<div class="contact-card__icon">
								<img src="@/assets/images/azure.svg" alt="Microsoft Azure" />
							</div>
							<p class="contact-card__title">
								Microsoft Azure
							</p>
						</div>
						<div class="contact-card">
							<div class="contact-card__icon">
								<img src="@/assets/images/google-cloud.svg" alt="Google Cloud Platform (GCP)" />
							</div>
							<p class="contact-card__title">Google Cloud Platform (GCP)</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="section choose-us-section">
			<div class="container">
				<div class="content">
					<h3 class="title">Ready to take your business to the cloud?</h3>
					<p>
						<b>Contact us today</b> to discuss your cloud application development needs. Our team of dedicated professionals will
						collaborate with you to create innovative cloud solutions that drive growth, improve efficiency, and propel your business
						forward.
					</p>
				</div>
				<div class="get-in-touch">
					<CustomButton class="custom-btn primary">
						<router-link to="/contact">
							Get Started Today
						</router-link>
					</CustomButton>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
import { mapActions } from "vuex";
import Subtitle from "@/components/utils/Subtitle.vue";
import CustomButton from "@/components/utils/CustomButton.vue";
import Title from "@/components/utils/Title.vue";

export default {
	name: "CloudApplicationDevelopment",
	components: { Subtitle, CustomButton, Title },
	metaInfo: {
		title: "Cloud Application Development | Enterprise Solutions",
		meta: [
			{
				name: "description",
				content:
					"AV DEVS is a one stop solution provider for any technology development needs. We are the only technology partner you would ever need. We engage dedicated resources in technologies like PHP, Laravel, .Net, Java, Python, Node.js, React.js, Vue.js, Angular, iOS Swift, Android Kotlin, React native, QA with Automation, DevOps, Machine Learning and AI, Graphic design and Digital Marketing. ",
			},
			{
				name: "og:title",
				content: "The only technology partner you will ever need | Enterprise Solutions",
			},
			{
				name: "og:description",
				content:
					"AV DEVS is a one stop solution provider for any technology development needs. We are the only technology partner you would ever need. We engage dedicated resources in technologies like PHP, Laravel, .Net, Java, Python, Node.js, React.js, Vue.js, Angular, iOS Swift, Android Kotlin, React native, QA with Automation, DevOps, Machine Learning and AI, Graphic design and Digital Marketing. ",
			},
		],
	},
	data() {
		return {
			isCollapsed: 1,
		};
	},
	methods: {
		...mapActions(["fillTargets"]),
		showCollapse(i) {
			this.isCollapsed === i ? (this.isCollapsed = false) : (this.isCollapsed = i);
		},
	},
	mounted() {
		this.fillTargets();
	},
};
</script>

<style lang="scss" scoped>
.slide-enter-active,
.slide-leave-active {
	transition: height 0.3s;
}
.slide-enter,
.slide-leave-to /* .slide-leave-active in <2.1.8 */ {
	height: 0;
	overflow: hidden;
}

.hero-section {
	background: url("~@/assets/images/cloud_bg.svg") center/cover;
}

.title {
	margin-bottom: 0.5em;
}

.contact-section {
	.contact-container {
		.section-head {
			text-align: center;

			p {
				color: $text-gray;
			}
		}
	}
	.contact-cards {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 3.75rem;
		margin-bottom: 4rem;

		.contact-card {
			background-color: $white;
			box-shadow: $subtle-shadow;
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 2.25rem 1rem;

			&__icon {
				width: 6rem;
				margin: 0 auto 1rem;
			}

			&__title {
				font-size: 1.25rem;
				color: $text-gray;
			}

			&:nth-of-type(2) {
				.contact-card__title {
					font-size: 1.175rem;
				}
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.contact-section {
		.contact-cards {
			grid-template-columns: repeat(1, 1fr);
		}
	}
}

.services-section-inverse {
	padding: 50px 0;
	background-color: $secondary-color;
	color: $white;
	position: relative;
	isolation: isolate;

	.grid-item {
		margin: 10px 0px;
	}

	.content-col .content button {
		width: 100%;
		background-color: $blue;
		color: $white;
		padding: 2rem 1rem;
		text-align: start;
		font-weight: 700;
		font-size: 20px;
		border: none;
		display: flex;
		align-items: center;
		position: relative;
		cursor: pointer;
	}
	.content-col .content button::before {
		display: inline-block;
		content: "";
		border: 0.4rem solid $white;
		border-radius: 50%;
		height: 0.5rem;
		width: 0.5rem;
		margin: 0 1rem;
	}

	.content-col .content button > img {
		height: 22px;
		width: auto;
		position: absolute;
		right: 2rem;
	}

	.content-col .content p {
		width: 100%;
		border: none;
		background-color: $white;
		color: $text-gray;
		font-weight: 400;
		font-size: 16px;
		padding: 2rem 3.5rem;
	}

	&::after {
		content: "";
		background: $secondary-color url("~@/assets/images/dotted-black.svg") no-repeat right bottom;
		background-size: contain;
		position: absolute;
		right: 0px;
		bottom: 150px;
		width: 100px;
		height: 200px;
		z-index: -1;
	}
	&::before {
		content: "";
		background: $secondary-color url("~@/assets/images/line.svg") no-repeat right bottom;
		background-size: contain;
		position: absolute;
		left: 600px;
		top: 100px;
		width: 50px;
		height: 50px;
		z-index: -1;
	}
}

.services-section {
	padding: 50px 0;
	background-color: $white;
	position: relative;
	isolation: isolate;

	&::after {
		content: "";
		background: #fff url("~@/assets/images/dotted.svg") no-repeat right bottom;
		background-size: contain;
		position: absolute;
		right: 0px;
		bottom: 150px;
		width: 100px;
		height: 200px;
		z-index: -1;
	}
	&::before {
		content: "";
		background: #fff url("~@/assets/images/frame.svg") no-repeat right bottom;
		background-size: contain;
		position: absolute;
		right: 60px;
		top: 150px;
		width: 50px;
		height: 50px;
		z-index: -1;
	}
}
.benefits-section {
	color: #3e4d5c;
	background: darken($white, 1%) url("~@/assets/images/rectangle.svg") center/contain;
	.benefits-container {
		text-align: center;
		padding-bottom: 3.2rem;

		.benefits-grid {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 3vw;
			margin-top: 50px;

			@media screen and (max-width: 1600px) {
				grid-template-columns: repeat(2, 1fr);
			}

			@media screen and (max-width: 992px) {
				grid-template-columns: repeat(2, 1fr);
			}

			@media screen and (max-width: 576px) {
				grid-template-columns: repeat(1, 1fr);
			}
		}
	}
}

.choose-us-section {
	background-color: $secondary-color;
	padding: 4rem;
	.title {
		color: $white;
		font-size: 30px;
		font-weight: 600;
		text-transform: none !important;
	}

	p {
		color: #d0dbe5;
		font-weight: 100;
		text-transform: none !important;
	}

	.container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.content {
		width: 60%;
	}

	.get-in-touch {
		width: 40%;
		justify-content: end;
		display: flex;
	}

	@media screen and (max-width: 992px) {
		padding-left: 0;
		padding-right: 0;

		.title {
			font-size: 24px;
		}
		.content {
			width: 100%;
			justify-content: center;
		}

		.get-in-touch {
			width: 100%;
			justify-content: center;
		}

		.container {
			flex-direction: column;
		}
	}

	@media screen and (max-width: 576px) {
		.content {
			width: 100%;
			justify-content: center;
		}

		.get-in-touch {
			width: 100%;
			justify-content: center;
		}

		.container {
			flex-direction: column;
		}
	}
}

.expert-section {
	padding-bottom: 120px;
}

.levels {
	svg {
		width: 100%;

		@media screen and (max-width: 1200px) {
			display: none;
		}
	}
	.level-content {
		height: 100%;
		text-align: center;
		display: flex;
		justify-content: center;
		flex-direction: column;
	}
	.level-title {
		color: $primary-color;
		font-size: 1.875rem;
		font-weight: 700;
		margin-bottom: 0.5em;
	}
	.level-text {
		// font-size: 0.875rem;
		color: $text-dark;
	}

	.level {
		display: none;
		background-color: $white;
		box-shadow: 0 30px 40px rgb(226 230 233 / 25%);
		padding: 40px;
		max-width: 80%;
		margin: 0 auto;
		margin-bottom: 20px;

		@media screen and (max-width: 1200px) {
			display: block;
		}

		@media screen and (max-width: 768px) {
			max-width: none;
		}
	}
}
</style>
